<template>
  <ak-container class="ak-card-table" card :bodyStyle="{padding: 0}">
    <a-table ref="table" :loading="loading" rowKey="id" :columns="columns" :data-source="tableList"
      style="width:96%;margin:30px auto">
      <!-- 操作 -->
      <template #action>
        <a>详情</a>
        <a-divider type="vertical" />
        <a>删除</a>
      </template>
    </a-table>
  </ak-container>
</template>

<script>
  import * as http from '@/api/set/http.api'

  const columns = [{
    title: 'ID',
    dataIndex: 'id'
  }, {
    title: '平台类型名称',
    dataIndex: 'typeName',
  }]

  export default {
    data() {
      return {
        loading: false,
        queryFilters: {},
        columns,
        tableList: [],
      }
    },
    created() {
      this.queryData()
    },
    methods: {
      // 加载数据方法
      queryData() {
        this.loading = true
        http.getPlatformType({
          ...this.queryFilters
        }).then((res) => {
          // eslint-disable-next-line no-debugger
          // debugger
          if (res.code === 0) {
            this.tableList = res.data
            this.pagination = res.data.total
          }
          this.loading = false
        }).catch(e => {
          this.message = e.message || '查询错误'
        }).finally(() => {
          this.loading = false
        })
      }
    }
  }
</script>
